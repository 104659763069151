import { Component, OnInit, Input } from '@angular/core';
import { Card } from '../../interfaces/passera'
import { confirm } from 'devextreme/ui/dialog';
import { DbService } from '../../services'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() card: Card

  faTimesCircle = faTimesCircle;

  

  constructor(public db: DbService) { }

  ngOnInit(): void {
  }

  editCard() {
    this.db.editVisible = true
  }

  editDone(e) {
    
    this.db.editVisible = false

    // Save to database
    this.db.httpPost('updateone', { system: this.db.system, table: 'cards', condition: { id: this.card.id }, data: { fornamn: this.card.fornamn, efternamn: this.card.efternamn }, token: this.db.token })


  }

  async removeCard() {

    let answer = await confirm(`Vill du ta bort kort ${this.card.nr} från hushållet?`, "Är du säker?")
    if (!answer) return false

    // Save to database
    await this.db.httpPost('updateone', { system: this.db.system, table: 'cards', condition: { id: this.card.id }, data: { fornamn: '', efternamn: '', idhushall: null, household_id: null }, token: this.db.token })

    this.db.household.cards = this.db.household.cards.filter(c => c.nr != this.card.nr)

  }

}
