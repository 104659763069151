import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from '../../services'
import { confirm } from 'devextreme/ui/dialog';
import { v4 as uuid } from 'uuid'
import * as moment from 'moment';
import { Household, Card } from '../../interfaces/passera'
import { faPlusCircle, faEye } from '@fortawesome/pro-light-svg-icons'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-household',
  templateUrl: './household.component.html',
  styleUrls: ['./household.component.scss']
})
export class HouseholdComponent implements OnInit {

  faPlusCircle = faPlusCircle; faEye = faEye;
  
  divider = 40

  errorMessage

  constructor(public db: DbService, private router: Router) {
    console.log('hh constr')
    if (!db.households.length) { this.router.navigate(['/start']); return }
  }

  ngOnInit(): void {
  }

  async activate() {

    let answer = await confirm(`Vill du aktivera hushållet?`, "Är du säker?")
    if (!answer) return false

    this.db.household.active = true

    await this.db.httpPost('updateone', { system: this.db.system, table: 'households', condition: { id: this.db.household.id }, data: { active: this.db.household.active }, token: this.db.token })
    await this.db.httpPost('insertone', { system: this.db.system, table: 'activations', data: { id: uuid(), idhousehold: this.db.household.id, ts: moment().format('YYYY-MM-DD HH:mm:ss'), action: 'Aktiverad' }, token: this.db.token })

    await this.getActivations()

  }

  async inActivate() {

    let answer = await confirm(`Vill du inaktivera hushållet?`, "Är du säker?")
    if (!answer) return false

    this.db.household.active = false

    await this.db.httpPost('updateone', { system: this.db.system, table: 'households', condition: { id: this.db.household.id }, data: { active: this.db.household.active }, token: this.db.token })
    await this.db.httpPost('insertone', { system: this.db.system, table: 'activations', data: { id: uuid(), idhousehold: this.db.household.id, ts: moment().format('YYYY-MM-DD HH:mm:ss'), action: 'Inaktiverad' }, token: this.db.token })

    await this.getActivations()

  }

  async getActivations() {

    this.db.activations = await <any>this.db.httpPost('find', { system: this.db.system, table: 'activations', condition: { idhousehold: this.db.household.id }, sort: { ts: -1 } })

  }  

  dragEnd(e) {
    localStorage.setItem('divider', Math.round(e.sizes[0]).toString())
  }

  async fieldChanged(e) {
    
    //console.log(e)
    
    if (!this.db.household.id) {

      // Add
      if (this.db.household.firstname && this.db.household.lastname) {

        let highestHousehold = await <any>this.db.httpPost('find', { system: this.db.system, table: 'households', condition: {}, sort: { id: -1 }, limit: 1 })
        if (!highestHousehold.length) return

        this.db.household.id = highestHousehold[0].id + 1

        await this.db.httpPost('insertone', { system: this.db.system, table: 'households', data: this.db.household, token: this.db.token })
        this.db.households.push(this.db.household)

        this.db.createNames()

      }

    } else {
      
      // Update
      this.db.httpPost('updateone', { system: this.db.system, table: 'households', condition: { id: this.db.household.id }, data: { [e.dataField]: e.value }, token: this.db.token })
      let n = this.db.names.find(n => n.id == this.db.household.id)
      if (n) n.name = `${this.db.household.firstname} ${this.db.household.lastname}, ${this.db.household.address}`

    }
    

  }

  initCard() {

    this.db.card = {
      id: 0,
      idhushall: 0,
      nr: '',
      fornamn: '',
      efternamn: '',
      active: true,
      birthday: '',
      created: moment().format('YYYY-MM-DD HH:mm:ss'),
      modified: '',
      gender: '',
      mobiltelefon: '',
      household_id: 0
    }

  }

  addCard() {

    this.initCard()

    this.db.addVisible = true

  }

  async doAdd(e) {

    console.log(this.db.card.nr.length, this.db.card.fornamn.length, this.db.card.efternamn.length)

    if (this.db.card.nr.length != 8 || this.db.card.fornamn.length < 3 || this.db.card.efternamn.length < 3) {
      this.showError('Felaktig värde')
      return
    }

    console.log(this.db.card)

    let card: Card = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'cards', condition: { nr: this.db.card.nr } })
    if (!card) {
      this.showError('Kortnummer existerar inte')
      return
    }

    if (card.household_id) {
      this.showError('Kortet används i redan i ett hushåll')
      return
    }

    delete card['_id']
    card.idhushall = this.db.household.id
    card.household_id = this.db.household.id
    card.fornamn = this.db.card.fornamn
    card.efternamn = this.db.card.efternamn
    
    this.db.cards.push(card)
    this.db.household.cards = this.db.cards.filter(c => c.household_id == this.db.household.id)
    this.db.httpPost('updateone', { system: this.db.system, table: 'cards', condition: { id: card.id }, data: card, token: this.db.token })

    this.db.addVisible = false
    

  }

  showError(message) {
    this.errorMessage = message
    setTimeout(() => { this.errorMessage = '' }, 3000)
  }  

}
