import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import * as moment from 'moment';
import { v4 as uuid } from 'uuid'
import { BnNgIdleService } from 'bn-ng-idle'

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent implements OnInit {

  faPlusCircle = faPlusCircle; faMinusCircle = faMinusCircle;

  constructor(public db: DbService, private bnIdle: BnNgIdleService) {
    this.getData()
    //setInterval(() => { this.getData}, 5 * 60 * 1000)
  }

  ngOnInit(): void {

    this.bnIdle.startWatching(500).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        console.log('idle');
        if (this.db.addVisible || this.db.editVisible) return
        this.getData()
      }
    })

  }

  async getData()  {
    await this.getInpasseringar()
    await this.getManuella()
    await this.getLatestLog()
  }

  async add(q) {

    this.db.manuella += q

    if (this.db.manuella < 0) this.db.manuella = 0

    let intrade = {
      id: uuid(),
      datum: moment().format('YYYY-MM-DD'),
      vuxna: this.db.manuella
    }

    let result1 = await <any>this.db.httpPost('findOne', { system: this.db.system, table: 'intraden', condition: { datum: moment().format('YYYY-MM-DD') } })
    if (result1) {
      let result2 = await <any>this.db.httpPost('updateOne', { system: this.db.system, table: 'intraden', condition: { id: result1.id }, data: { intraden: this.db.manuella } })
    } else {
      let result3 = await <any>this.db.httpPost('insertOne', { system: this.db.system, table: 'intraden', data: { id: uuid(), datum: moment().format('YYYY-MM-DD'), intraden: this.db.manuella } })
    }

  }

  async getInpasseringar() {
    let result = await <any>this.db.httpPost('count', { system: this.db.system, table: 'scans', condition: { ts: { $regex: `^${moment().format('YYYY-MM-DD')}` }, action: { $regex: `^Inpassering` } } })
    this.db.inpasseringar = result.rows
  }

  async getManuella() {
    let result = await <any>this.db.httpPost('findOne', { system: this.db.system, table: 'intraden', condition: { datum: moment().format('YYYY-MM-DD') } })
    this.db.manuella = result ? result.vuxna : 0
  }

  async getLatestLog() {
    let scans = await <any>this.db.httpPost('find', { system: this.db.system, table: 'scans', condition: {}, sort:  { ts: -1 }, limit: 20 })
    scans.forEach(scan => {
      this.db.startpageLog.push(`${scan.ts.substr(0, 16)} ${scan.scandata} ${scan.action}`)
    })
  }

}
