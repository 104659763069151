import { Component, OnInit, ViewChild } from '@angular/core';
import { DbService } from '../../services'
import * as moment from 'moment';
import 'moment/locale/sv';
import { v4 as uuid } from 'uuid'
import { DxDataGridComponent } from "devextreme-angular";
import { Router } from '@angular/router';

@Component({
  selector: 'app-inpasseringar',
  templateUrl: './inpasseringar.component.html',
  styleUrls: ['./inpasseringar.component.scss']
})
export class InpasseringarComponent implements OnInit {

  @ViewChild('grid', { static: false}) grid: DxDataGridComponent

  inpasseringar: Inpassering[] = []

  year = moment().format('YYYY')
  today = moment().format('YYYY-MM-DD')
  startDate = `${this.year}-05-15`
  endDate = `${this.year}-09-15`

  inpasseringarDataSource: any = {}

  gridFilterValue = []//['year', '=', moment().format('YYYY')]

  constructor(public db: DbService, private router: Router) {
    if (!db.households.length) { this.router.navigate(['/start']); return }
    moment().locale('sv')
    this.db.createDataSource(this.db.system, 'inpasseringar', this.inpasseringarDataSource)
    this.getInpasseringar()
  }

  ngOnInit(): void {
  }

  async getInpasseringar() {

    let inpasseringar = await <any>this.db.httpPost('find', { system: this.db.system, table: 'inpasseringar', condition: { year: this.year }, sort: { date: 1 } })
    console.log(1, inpasseringar)

    let d = `${this.year}-05-15`
    console.log(2, d)

    // Populate array until today
    while (d <= moment().format('YYYY-MM-DD') && d <= this.endDate) {

      //console.log(d)

      let i: Inpassering = inpasseringar.find(i => i.date == d)
      if (i) {
        console.log(d, 'Exists')
      } else {

        i = {
          id: uuid(),
          year: this.year,
          date: d,
          week: +moment(d).format('W'),
          dayName: moment(d).format('dddd'),
          inpasseringar: 0,
          inpasseringarUpdated: false,
          manuella: 0
        }

        console.log('Inserting', d)
        await this.db.httpPost('insertone', { system: this.db.system, table: 'inpasseringar', data: i, token: this.db.token })
        
      }

      this.inpasseringar.push(i)

      d = moment(d).add(1, 'day').format('YYYY-MM-DD')
    }

    // Check if scans are updated
    for (let i of this.inpasseringar.filter(i => !i.inpasseringarUpdated)) {

      let result = await <any>this.db.httpPost('count', { system: this.db.system, table: 'scans', condition: { ts: { $regex: `^${i.date}` }, action: { $regex: `^Inpassering` } } })
      let count = result ? result.rows : 0

      //console.log('Count', i.date, count)

      await this.db.httpPost('updateone', { system: this.db.system, table: 'inpasseringar', condition: { date: i.date }, data: { inpasseringarUpdated: (i.date == this.today ? false : true), inpasseringar: count }, token: this.db.token })
   
    }

    if (this.grid) this.grid.instance.refresh()

  }

  calculateDaySum = (i: Inpassering) => {
    return i.inpasseringar + i.manuella
  }


}

export interface Inpassering {
  id: string
  year: string
  week: number
  date: string
  dayName: string
  inpasseringar: number
  inpasseringarUpdated: boolean
  manuella: number
}
