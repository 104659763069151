<div class="outer">

    <dx-data-grid 
        [dataSource]="log" 
        [columnAutoWidth]="true" 
        [hoverStateEnabled]="true" 
        (onToolbarPreparing)="onToolbarPreparing($event)"
        >

        <dxo-filter-row visible="true"></dxo-filter-row>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 50, 100]"></dxo-pager>

        
        <dxi-column dataField="ts" dataType="string" caption="Tidpunkt" sortOrder="desc" width="165" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="scandata" dataType="string" caption="Kortnummer" width="100"></dxi-column>
        <dxi-column dataField="action" dataType="string" caption="Händelse"></dxi-column>
        

        <div *dxTemplate="let data of 'gridHeadingTemplate'">
            <div class="gridheading">Logg</div>
        </div>

    </dx-data-grid>

</div>