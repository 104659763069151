<div class="outer">

    <dx-data-grid 
        #ordergrid 
        [dataSource]="householdsDataSource" 
        [columnAutoWidth]="true" 
        [hoverStateEnabled]="true" 
        (onToolbarPreparing)="db.onToolbarPreparing($event)"
        >

        <dxo-filter-row visible="true"></dxo-filter-row>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 50, 100]"></dxo-pager>

        <dxo-remote-operations [filtering]="true" [sorting]="false" [paging]="true"></dxo-remote-operations>

        <dxo-export [enabled]="true" fileName="households"></dxo-export>
        <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true" [selectTextOnEditStart]="true"></dxo-editing>
        
        <dxi-column dataField="id" dataType="number" caption="Nummer" sortOrder="asc" width="120" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="firstname" dataType="string" caption="Förnamn"></dxi-column>
        <dxi-column dataField="lastname" dataType="string" caption="Efternamn"></dxi-column>
        <dxi-column dataField="address" dataType="string" caption="Adress"></dxi-column>
        <dxi-column dataField="postcode" dataType="string" caption="Postnr"></dxi-column>
        <dxi-column dataField="city" dataType="string" caption="Ort"></dxi-column>
        <dxi-column dataField="phone" dataType="string" caption="Telefon"></dxi-column>
        <dxi-column dataField="email" dataType="string" caption="E-post"></dxi-column>
        <dxi-column [calculateCellValue]="cardCount" [allowSorting]="true" caption="Kort" width="120"></dxi-column>
        <dxi-column dataField="active" dataType="boolean" caption="Aktiv" [allowEditing]="false"></dxi-column>

        <dxi-column type="buttons" [width]="100">
            <dxi-button hint="Visa hushåll" [icon]="db.faSvg(faHouse)" [onClick]="gotoHousehold"></dxi-button>
        </dxi-column>
    
        <div *dxTemplate="let data of 'gridHeadingTemplate'">
            <div class="gridheading">Hushåll <fa-icon [icon]="faPlusCircle" class="addicon" (click)="addHousehold()"></fa-icon></div>
        </div>

    </dx-data-grid>

    <!--
    <div style="padding: 20px 0">
        <button (click)="inaktivera()">Inaktivera</button>
    </div>
    -->
    
</div>