import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import {  DxScrollViewModule, DxToolbarModule,  DxSelectBoxModule, DxContextMenuModule, DxButtonModule, DxAutocompleteModule, DxTextBoxModule, DxPopupModule, DxFormModule, DxNumberBoxModule, DxDrawerModule, DxTreeViewModule, DxTextAreaModule, DxDataGridModule, DxValidatorModule, DxSwitchModule, DxLoadPanelModule } from 'devextreme-angular'

import { AngularSplitModule } from 'angular-split';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { BnNgIdleService } from 'bn-ng-idle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopComponent } from './components/top/top.component';
import { MainComponent } from './components/main/main.component';
import { CardComponent } from './components/card/card.component';
import { CardsComponent } from './components/cards/cards.component';
import { HouseholdsComponent } from './components/households/households.component';
import { LogComponent } from './components/log/log.component';
import { CountersComponent } from './components/counters/counters.component';
import { InpasseringarComponent } from './components/inpasseringar/inpasseringar.component';
import { HouseholdComponent } from './components/household/household.component';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'db10.bokad.se',
  port: 9002,
  protocol: 'wss',
  path: '',
  username: 'printer',
  password: 'retnirp'
};

@NgModule({ declarations: [
        AppComponent,
        TopComponent,
        MainComponent,
        CardComponent,
        CardsComponent,
        HouseholdsComponent,
        LogComponent,
        CountersComponent,
        InpasseringarComponent,
        HouseholdComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        AngularSplitModule,
        FontAwesomeModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        DxScrollViewModule, DxToolbarModule, DxSelectBoxModule, DxContextMenuModule, DxButtonModule, DxAutocompleteModule, DxTextBoxModule, DxPopupModule, DxFormModule, DxNumberBoxModule, DxDrawerModule, DxTreeViewModule, DxTextAreaModule, DxDataGridModule, DxValidatorModule, DxSwitchModule, DxLoadPanelModule], providers: [BnNgIdleService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
