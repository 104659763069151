<div *ngIf="li">
    
    <app-top></app-top>

    <dx-drawer 
        template="template"
        [(opened)]="db.drawerOpen"
        position="right"
        height="100%"
        openedStateMode="overlap"
        [closeOnOutsideClick]="true">

        <div *dxTemplate="let data of 'template'" class="drawer">
        
            <div class="ac">
                <h2>Meny</h2>
            </div>

            <div>
                <dx-tree-view [dataSource]="menuTree"  displayExpr="name" [selectByClick]="true" (onItemSelectionChanged)="menuChange($event)" selectionMode="single"></dx-tree-view>    
            </div>

            <div class="sw">
                <dx-switch [(value)]="db.scannerSwitch"></dx-switch>
                <span style="margin-left: 12px;">Scanner</span>
            </div>
            
        </div>

        <router-outlet></router-outlet>

    </dx-drawer>

</div>

<div *ngIf="!li">

    <form #userlogin="ngForm" (ngSubmit)="onClickSubmit(userlogin.value)">

        <table style="margin: 0 auto; margin-top: 50px;">
            <tr>
                <td>Username</td>
                <td><dx-text-box name="username" ngModel></dx-text-box></td>
            </tr>
            <tr>
                <td>Password</td>
                <td><dx-text-box mode="password" name="password" ngModel></dx-text-box></td>
            </tr>
            <tr>
                <td colspan="2" style="padding: 20px; text-align: center;"><dx-button type="default" text="Login" [useSubmitBehavior]="true"></dx-button></td>
            </tr>
        </table>    

    </form>

</div>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.7)"
  [shading]="true"
  message="Loading"
  [visible]="db.loading">
</dx-load-panel>