<div class="first noselect">

    <div class="topbox t1">
        <div class="trub">Inpasseringar</div>
        <div class="tdata">{{db.inpasseringar}}</div>
    </div>

    <!--
    <div class="topbox t2">
        <div class="trub">Manuella in</div>
        <div class="tdata">
            <fa-icon [icon]="faMinusCircle" class="plusminus" (click)="add(-1)"></fa-icon>
            <div>{{db.manuella}}</div>
            <fa-icon [icon]="faPlusCircle" class="plusminus" (click)="add(1)"></fa-icon>
        </div>        
    </div>

    <div class="topbox t3">
        <div class="trub">Totalt</div>
        <div class="tdata">
            <div>{{ db.inpasseringar + db.manuella }}</div>
        </div>
    </div>
    -->

</div>

<div class="second">

    <div *ngFor="let sl of db.startpageLog" class="logitem">
        {{sl}}
    </div>

</div>
