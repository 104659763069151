<div class="top">

    <dx-toolbar>

        <!-- Logo -->
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate >                
                <div style="display: flex; align-items: center;margin-right: 50px;">
                    <div><img src="/assets/himnalogo.png" class="logo"></div>
                    
                </div>
            </div>        
        </dxi-item>

        <dxi-item location="left">
            <div *dxTemplate>
                <dx-autocomplete
                    width="400"
                    placeholder="Ange förnamn, efternamn eller gatuadress"
                    [dataSource]="db.names"
                    [showClearButton]="true"
                    valueExpr="name"
                    (onSelectionChanged)="namesChanged($event)"
                >
                    <div *dxTemplate="let item of 'item'">
                        <span *ngIf="item.type == 'household' && item.active == true" style="margin-right: 10px; color: green"><fa-icon [icon]="faHouse"></fa-icon></span>
                        <span *ngIf="item.type == 'household' && item.active == false" style="margin-right: 10px; color: red"><fa-icon [icon]="faHouse"></fa-icon></span>
                        <span *ngIf="item.type == 'card'" style="margin-right: 10px; color: blue"><fa-icon [icon]="faUser"></fa-icon></span>
                        <span>{{ item.name }}</span>
                    </div>
                </dx-autocomplete>
            </div>
        </dxi-item>

        <!--
        <dxi-item 
            location="left"
            widget="dxAutocomplete"
            locateInMenu="never" 
            [options]="{ width: '400px', placeholder: 'Ange förnamn, efternamn eller gatuadress', dataSource: db.names, showClearButton: true, valueExpr: 'name', onSelectionChanged: namesChanged }">
        </dxi-item>
        -->

        <!-- Add household 
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate>                
                <fa-icon [icon]="faPlusCircle" class="addicon" (click)="addHousehold()"></fa-icon>
            </div>        
        </dxi-item>
        -->

        <!-- Start -->
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate>                
                <fa-icon [icon]="faInfoCircle" class="addicon" (click)="startClick()"></fa-icon>
            </div>        
        </dxi-item>

        <!-- Cards -->
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate>                
                <fa-icon [icon]="faAddressCard" class="addicon" (click)="cardsClick()"></fa-icon>
            </div>        
        </dxi-item>

        <!-- Households -->
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate>                
                <fa-icon [icon]="faHouse" class="addicon" (click)="householdsClick()"></fa-icon>
            </div>        
        </dxi-item>

        <!-- Inpasseringar -->
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate>                
                <fa-icon [icon]="faRunning" class="addicon" (click)="logClick()"></fa-icon>
            </div>        
        </dxi-item>

        <!-- Statistics -->
        <dxi-item location="left" locateInMenu="never">
            <div *dxTemplate>                
                <fa-icon [icon]="faChartBar" class="addicon" (click)="chartClick()"></fa-icon>
            </div>        
        </dxi-item>

        <!--
        placeholder="Ange förnamn, efternamn eller gatuadress"
                    [(value)]="searchText"
                    [dataSource]="db.names"
                    [showClearButton]="true"
                    [spellcheck]="false"
                    valueExpr="name"
                    (onSelectionChanged)="namesChanged($event)"
        -->

        <!-- Menu 
        <dxi-item location="after" widget="dxButton" [options]="{ icon: 'menu', onClick: menuClick }"></dxi-item>
        -->

    </dx-toolbar>
    
</div>